/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Popup {
  color: $greyDarkest;
  text-align: center;
}

.PopupTitle {
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-bottom: 12px;
}

.PopupText {
  color: $greyDarker;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-bottom: 38px;
}

.PopupProductImage {
  margin-bottom: 16px;
  max-width: 160px;
}

.PopupProductName {
  font-size: $fontSize-sm;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 27px;
}

.PopupStars {
  margin-bottom: 38px;

  > div {
    margin-left: auto;
    margin-right: auto;
  }
}

.PopupForm {

  button {
    margin: 8px auto 32px;
    padding: 0 20px;
    width: auto;
  }
}

.PopupCancel {
  text-align: center;
}

.AccountReview {
  background: $greyLightestPlus;
  border-radius: 10px;
  display: flex;
  margin-bottom: 24px;
  padding: 24px 0 32px;
}

.AccountReviewProduct {
  flex: 0 0 auto;
  padding: 0 24px;
  width: 30%;
}

.AccountReviewProductName {
  color: $greyDarker;
  display: block;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 22px;
}

.AccountReviewCategory {
  display: block;
  font-size: $fontSize-sm;
  font-weight: $font-weight-s;
  letter-spacing: 0.04em;
  line-height: 19px;
}

.AccountReviewForm {
  flex: 1 1 auto;
  text-align: left;
}

.AccountReviewFormFrame {
  border-left: 1px solid $greyLightest;
  padding: 0 24px;
}

.AccountReviewFormName {
  color: $greyDarker;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 15px;
}

.AccountReviewStars {
  display: flex;
  margin-bottom: 15px;
}

.AccountReviewDetail textarea {
  margin-bottom: 0;
}

.AccountReviewButton {
  margin: 24px auto 0;
  max-width: 100%;
  width: 316px;
}

.DisclaimerText {
  font-size: $fontSize-sm;
  margin-bottom: 20px;

  :global {

    a {
      color: $purpleDarker;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .AccountReviewProduct {
    width: 38%;
  }

  .AccountReviewFormFrame {
    border-left: 0;
    padding: 0 24px 0 0;
  }
}

@media screen and ( $mobile-media ) {

  .AccountReview {
    display: block;
    padding: 24px 16px 36px;
  }

  .AccountReviewProduct {
    padding: 0 0 24px;
    width: 100%;
  }

  .AccountReviewFormFrame {
    border-top: 1px solid $greyLightest;
    padding: 24px 0 0;
  }

  .AccountReviewForm {
    text-align: center;
  }

  .AccountReviewStars {
    display: block;
  }

  .AccountReviewButton {
    max-width: 316px;
    width: 100%;
  }
}
