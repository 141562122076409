/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ReviewsAdd {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  color: $greyDarkest;
  margin-left: 0;
  margin-top: 5px;
  max-width: 610px;
  padding: 32px 0;
  text-align: center;
}

.ReviewsAddHeader {
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 16px;
}

.ReviewsAddText {
  color: $greyDarker;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-bottom: 24px;

  p {
    margin: 0 20px;
  }
}

.ReviewsAddButton {
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ReviewsAdd {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    max-width: 535px;
  }

  .ReviewsAddHeader {
    margin-bottom: 5px;
  }

  .ReviewsAddText {
    margin-bottom: 13px;
  }
}
