/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.PaybackInfoWrapper {
  align-items: center;
  display: flex;
  gap: 12px;
  padding: 12px 0px;

  a {
    display: flex;
  }

  .PaybackInfoTxt {
    color: $greyLighter;
    flex: 1;
    font-size: $fontSize-s;
  }
}
