@import '../../../../../assets/css/variables/variables';

.RedirectTitleInfo {
  background-color: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  font-size: $fontSize-sm;
  font-weight: $font-weight-s;
  line-height: normal;
  margin: 24px 0;
  padding: 24px;
}
