/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Reviews {
  padding: 64px 0;

  > div {

    &:after {
      clear: both;
      content: '';
      display: table;
    }
  }
}

.ReviewsTitle {
  color: $greyDarkest;
  font-size: $fontSize-l;
  font-weight: $font-weight-l;
  line-height: 20px;
  margin-bottom: 18px;
}

.ReviewsRate {
  position: relative;
}

.ReviewsColumn {

  &:first-child {
    width: 34%;
  }

  &:nth-child(2) {
    flex: 1;
  }
}

.ReviewsNotice {
  flex-basis: 100%;
  font-size: $fontSize-sm;
  margin-top: 32px;
}

.ReviewsRateTotalSummary {
  color: $grey;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-top: 4px;
}

.ReviewsRateBars {
  color: $grey;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-top: 24px;
}

.ReviewsRateBar {
  align-items: center;
  display: flex;

  &:focus {
    outline: none;
  }
}

.ReviewsRateBarStar {
  background: url('../../../../../assets/images/reviews/star.png') no-repeat 12px 2px/14px 14px;
  position: relative;
  white-space: nowrap;
  width: 36px;

  &:before {
    background: $orange;
    content: '';
    display: block;
    height: 14px;
    left: 12px;
    position: absolute;
    top: 2px;
    width: 14px;
    z-index: -1;
  }
}

.ReviewsRateBarCount {
  margin-left: 2px;
  text-align: right;
  width: 45px;
}

.ReviewsRateBarProgress {
  border: 1px solid $orange;
  border-radius: 4px;
  height: 12px;
  position: relative;
  width: 95px;
}

.ReviewsRateBarSelected {

  & .ReviewsRateBarStar, & .ReviewsRateBarCount {
    font-weight: 800;
  }
}

.ReviewsRateBarSelectable {
  cursor: pointer;
}

.ReviewsRateBarProgressInner {
  background: linear-gradient(360deg, #cb9c36 6.25%, #ffc445 90.62%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
}

.ReviewsSorter {
  margin-top: 40px;
  width: 250px;
}

.ReviewsAdd {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  color: $greyDarkest;
  margin-top: 64px;
  padding: 32px 0;
  text-align: center;
}

.ReviewsAddHeader {
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 16px;
}

.ReviewsAddText {
  color: $greyDarker;
  font-size: $fontSize-sm;
  line-height: 22px;
  margin-bottom: 24px;
}

.ReviewsAddButton {
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Reviews {
    padding-bottom: 50px;
    padding-top: 32px;
  }

  .ReviewsColumn {

    &:first-child, &:last-child {
      width: 100%;
    }
  }

  .ReviewsSorter {
    margin-top: 32px;
    width: 100%;
  }

  .ReviewsTitle {
    font-size: $fontSize-m;
  }

  .ReviewsNotice {
    margin-top: 24px;
  }
}

@media screen and ( $mobile-media ) {

  .Reviews {
    padding-bottom: 24px;
  }
}
