/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Stars {
  position: relative;
  width: 156px;

  svg {
    height: 26px;
    margin-right: 4px;
    width: 28px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.StarsLarge {
    width: 243px;

    svg {
      height: 39px;
      margin-right: 8px;
      width: 42px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.StarsForm {

    svg {
      cursor: pointer;
    }

    .StarsFull {
      pointer-events: none;
    }
  }

  &.StarsError {

    svg {

      path {
        stroke: $redError;
        stroke-width: 0.5;
      }
    }
  }
}

.ViewStars {
  position: relative;
  width: 180px;
}

.StarsContainer {
  position: relative;
}

.StarsContainerView {
  background: $greyLightest;
  height: 36px;
  overflow: hidden;
  position: relative;
  width: 180px;

  &:before {
    background: url('../../../../../../assets/images/reviews/star.png') repeat-x 0 0/36px 36px;
    content: '';
    display: block;
    height: 36px;
    position: absolute;
    width: 180px;
    z-index: 1;
  }
}

.StarsPercent {
  background: $orange;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.StarsEmpty {
  position: relative;
}

.StarsFull {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all .3s $defaultBezier;
  white-space: nowrap;
  width: 50%;
}

.TotalReviews {
  color: $greyDarkest;
  font-size: $fontSize-sm;
  left: calc(100% + 6px);
  line-height: 36px;
  position: absolute;
  top: 0;
}
