/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SocialSharingWrapper {
  border-bottom: 1px solid $greyLightest;
}

.SocialSharing {
  margin-bottom: 48px;
  margin-top: 48px;
  width: 49%;
}

.SocialSharingPopUp {
  padding: 10px 32px 0 0;

  & + p {
    font-size: $fontSize-sm;
    margin-bottom: 15px;
  }
}

.SocialSharingContent {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 256px;
}

.SocialSharingItems {
  display: flex;
  justify-content: center;
}

.SocialSharingTitle {
  color: $greyLighter;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  position: absolute;
  right: 100%;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
}

.SocialSharingItem {
  border: 2px solid $greyLighter;
  border-radius: 50%;
  cursor: pointer;
  height: 32px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  transition: all .3s $defaultBezier;
  width: 32px;

  &:before {
    color: $greyLighter;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: $greyLighter;

    &:before {
      color: $white;
    }
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
  }
}

/* ------------------------------------*\
.SocialSharingFacebook {}

.SocialSharingPinterest {}

.SocialSharingRecruit {}
\*------------------------------------ */

.SocialSharingEmail {

  div {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .SocialSharing {
    margin-bottom: 35px;
    margin-top: 0;
    width: 100%;
  }

  .SocialSharingWrapper {
    border-bottom: 0;
  }
}

@media screen and ( $mobile-media ) {

  .SocialSharing {
    width: 100%;
  }

  .SocialSharingTitle {
    margin-bottom: 12px;
    position: relative;
    right: 0;
    text-align: center;
    top: 0;
    transform: none;
  }
}
