/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ReviewsList {
  background: $white;
  margin-top: 32px;
}

.ReviewItem {
  background: $greyLightestPlus;
  color: $greyDarkest;
  margin-bottom: 1px;
  padding: 48px 0 42px;

  > div {
    align-items: center;
    display: flex;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.ReviewItemSource {
  color: $greyLighter;
  padding-top: 16px;
}

.ReviewItemStars {

  > div {
    margin-top: 0;
  }
}

.ReviewItemHeadline {
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 22px;
  margin-bottom: 4px;
}

.ReviewItemPerson {
  color: $grey;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.ReviewItemDate {
  color: $grey;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  opacity: 0.7;
}

.ReviewItemLeft {
  margin-right: 20px;
  width: 250px;
}

.ReviewItemRight {
  color: $greyDarker;
  flex: 1;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.ReviewItemContent {
  max-width: 610px;
}

.ReviewsListLoadMore {
  margin-top: 32px;
}

.ReviewItemContentHidden {
  display: none;
}

.ReviewItemReadMore {
  cursor: pointer;
  font-weight: $font-weight-l;
  padding-left: 5px;
  text-decoration: underline;
  text-transform: lowercase;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ReviewItem {
    padding: 32px 0 48px;

    > div {
      display: block;
    }
  }

  .ReviewItemContent {
    max-width: 100%;
  }

  .ReviewItemDate {
    margin-bottom: 8px;
  }

  .ReviewsList {
    margin-top: 24px;
  }

  .ReviewsListLoadMore {
    margin-top: 24px;
  }
}

@media screen and ( $mobile-media ) {

  .ReviewItem {
    padding: 32px 0;
  }

  .ReviewsListLoadMore {
    padding: 0 16px;

    & > button {
      width: 100%;
    }
  }
}
